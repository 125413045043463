












import { Component, Vue } from "vue-property-decorator";
import TopBar from "./TopBar.vue";
import BottomBar from "./BottomBar.vue";

@Component({
  components: {
    TopBar,
    BottomBar
  }
})
export default class HomeLayout extends Vue {
  get isHomePage() {
    return this.$route.name == "home";
  }
}
