


































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  logo = require("@/assets/logo-w.svg");

  navDrawer = false;

  socialLinks = [
    {
      icon: "mdi-twitter",
      href: "https://twitter.com/kahev2018"
    },
    {
      icon: "mdi-facebook",
      href: "http://fb.me/kahev2018"
    },
    {
      icon: "mdi-youtube",
      href: "https://www.youtube.com/c/kahev2018"
    },
    {
      icon: "mdi-instagram",
      href: "https://www.instagram.com/kahev2018"
    }
  ];

  menuItems = [
    {
      title: "Hakkımızda",
      to: {
        name: "about-us"
      }
    },
    {
      title: "Burs",
      to: {
        name: "scholarship"
      }
    },
    {
      title: "Okul Desteği",
      to: {
        name: "school-support"
      }
    },
    {
      title: "Haberler",
      to: {
        name: "news"
      }
    },
    {
      title: "Basında KAHEV",
      to: {
        name: "press"
      }
    },
    {
      title: "Kadın İzi",
      to: {
        name: "magazine"
      }
    },
    {
      title: "SSS",
      to: {
        name: "faq"
      }
    }
  ];

  apps = [
    {
      title: "ONLINE İŞLEMLER",
      icon: "mdi-login-variant",
      href: "https://fonzip.com/kahev"
    },
    {
      title: "AKADEMİ",
      icon: "mdi-school",
      href: "https://kahevakademi.com"
    },
    {
      title: "DÜKKAN",
      icon: "mdi-store",
      href: "https://www.kahevdukkan.com"
    },
    {
      title: "ÜYELİK",
      icon: "mdi-cursor-default-click",
      href: "https://fonzip.com/kahev/form/uyelik"
    },
    {
      title: "BAĞIŞ SERTİFİKASI",
      icon: "mdi-certificate-outline",
      href: "/haberler/bagis-sertifikasi"
    },
    {
      title: "EN",
      icon: "mdi-translate",
      href: "/en"
    }
  ];

  dialog = false;
}
